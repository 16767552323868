<script setup>
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed, ref, watch } from 'vue';
import Pagination from './Pagination.vue';

const props = defineProps({
    regions: {
        type: Array,
        required: true,
    },
    levels: {
        type: Array,
        required: true,
    },
    religions: {
        type: Array,
        required: true,
    },
});

const region = ref(null);
const level = ref(null);
const religion = ref(null);

const page = ref(1);

const offset = computed(() => {
    return (page.value - 1) * 9;
});

const relatedTo = computed(() => {
    if (!region.value && !level.value && !religion.value) {
        return null;
    }

    return [region.value, level.value, religion.value].filter((item) => !!item);
});

const { result, loading, refetch } = useQuery(
    gql`
        query schools(
            $limit: Int!
            $offset: Int!
            $relatedTo: [QueryArgument]
        ) {
            count: entryCount(section: "schools", relatedToAll: $relatedTo)
            entries(
                limit: $limit
                offset: $offset
                section: "schools"
                relatedToAll: $relatedTo
                orderBy: "RAND()"
            ) {
                id
                title
                ... on school_Entry {
                    shortDescription
                    schoolFeaturedImage {
                        title
                        url
                    }
                    locality {
                        title
                    }
                    schoolLevel {
                        title
                    }
                    religion {
                        title
                    }
                    contactInformation {
                        ... on contactInformationBlock_Entry {
                            email
                            phone
                        }
                    }
                    website
                }
            }
        }
    `,
    {
        limit: 9,
        offset: offset,
        relatedTo: relatedTo,
    },
);

const updatePage = (newPage) => {
    page.value = newPage;
};

watch(relatedTo, () => {
    page.value = 1;
});
</script>

<template>
    <form class="flex flex-wrap md:flex-no-wrap -mx-4 -mb-8" @change="reload">
        <div class="w-full md:w-auto md:flex-1 px-4 mb-8">
            <label class="block w-full h5 mb-3" for="region"
                >Search by Region</label
            >

            <select
                class="fui-select block w-full"
                id="region"
                name="region"
                v-model="region"
            >
                <option :value="null">Select one&hellip;</option>

                <option
                    v-for="region in props.regions"
                    :key="region.id"
                    :value="region.id"
                >
                    {{ region.title }}
                </option>
            </select>
        </div>

        <div class="w-full md:w-auto md:flex-1 px-4 mb-8">
            <label class="block w-full h5 mb-3" for="level"
                >Search by Level</label
            >

            <select
                class="fui-select block w-full"
                id="level"
                name="level"
                v-model="level"
            >
                <option :value="null">Select one&hellip;</option>

                <option
                    v-for="level in props.levels"
                    :key="level.id"
                    :value="level.id"
                >
                    {{ level.title }}
                </option>
            </select>
        </div>

        <div class="w-full md:w-auto md:flex-1 px-4 mb-8">
            <label class="block w-full h5 mb-3" for="religion"
                >Search by Religion</label
            >

            <select
                class="fui-select block w-full"
                id="religion"
                name="religion"
                v-model="religion"
            >
                <option :value="null">Select one&hellip;</option>

                <option
                    v-for="religion in props.religions"
                    :key="religion.id"
                    :value="religion.id"
                >
                    {{ religion.title }}
                </option>
            </select>
        </div>
    </form>

    <div class="flex flex-wrap mt-12 -mx-4 -mb-8" :class="{ loading: loading }">
        <div
            class="w-full sm:w-1/2 md:w-1/3 px-4 mb-8"
            v-for="school in result?.entries"
            :class="{ 'opacity-25': loading }"
        >
            <div class="flex flex-col h-full overflow-hidden">
                <div
                    v-if="school.schoolFeaturedImage.length"
                    class="lazyload-container aspect aspect-16by9 relative overflow-hidden"
                >
                    <img
                        class="absolute top-0 left-0 w-full h-full object-cover lazyload"
                        src=""
                        :data-src="school.schoolFeaturedImage[0].url"
                        :alt="school.title"
                    />
                </div>

                <div v-else>
                    <img
                        class="block w-full bg-gray-200"
                        src=""
                        :alt="school.title"
                    />
                </div>

                <div class="flex-1 pt-4" :class="{ 'pb-8': !school.website }">
                    <h2 class="h5 mb-2 text-primary-600">{{ school.title }}</h2>

                    <p class="h6 mb-4" v-if="school.locality[1]">
                        {{ school.locality[1].title }}
                    </p>

                    <div
                        class="richtext text-gray-300"
                        v-html="school.shortDescription"
                    ></div>
                </div>

                <div class="pt-4 pb-8" v-if="school.website">
                    <a class="read-more" :href="school.website" target="_blank"
                        >website</a
                    >
                </div>
            </div>
        </div>
    </div>

    <Pagination
        :items="result?.count || 0"
        :items-per-page="9"
        :current="page"
        @changed="(page) => updatePage(page)"
    />
</template>
